import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { LoginData } from "../apiDataStruct/apidatastruct";
import { changePassword } from "../api/allapi";
import { enqueueSnackbar } from "notistack";
export default function ChangePassword(props) {
    const [state, setState] = useState({
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
    })
    const handleChangePassword = async () => {
        /*
        .add("username", username)
                .add("password", new_pass)
                .add("old_pass", old_pass)
                .add("client_id" , ADMRSAConnection.client_id)
                .add("client_secret" , ADMRSAConnection.client_secret)
                .add("grant_type" , "password")
                .add("confirmPassword", new_pass)*/
        const payload = {
            username: props.username,
            password: state.newPassword,
            old_pass: state.oldPassword,
            client_id: LoginData.client_id,
            client_secret: LoginData.client_secret,
            grant_type: "password",
            confirmPassword: state.confirmNewPassword,
        }
        try {
            let result = await changePassword(payload);

            enqueueSnackbar(`${result.data.errorDescription} `, { variant: result.data.error == 1 ? "error" : "success" })
        }
        catch (e) {
            enqueueSnackbar(`Lỗi ${e.message}`, { variant: "error" })
        }

    }
    return (
        <div>

            <div className=" w-full">
                <TextField
                    // onChange={(e) => { setState({ ...state, soDtKhachLienLac: e.target.value }) }}
                    variant="standard"
                    type="password"
                    onChange={(e) => {
                        setState((prevState) => ({ ...prevState, oldPassword: e.target.value }))
                    }}
                    inputProps={{
                        style: {
                            boxShadow: "none",
                            width: `calc(100vw - 60px)`,
                        },
                    }}
                    // value={state.soDtKhachLienLac}
                    label="Mật khẩu cũ"
                />
                <TextField
                    // onChange={(e) => { setState({ ...state, soDtKhachLienLac: e.target.value }) }}
                    variant="standard"
                    type="password"
                    onChange={(e) => {
                        setState((prevState) => ({ ...prevState, newPassword: e.target.value }))
                    }}
                    inputProps={{
                        style: {
                            boxShadow: "none",
                            width: `calc(100vw - 60px)`,
                        },
                    }}
                    // value={state.soDtKhachLienLac}
                    label="Mật khẩu mới"
                />
                <TextField
                    // onChange={(e) => { setState({ ...state, soDtKhachLienLac: e.target.value }) }}
                    variant="standard"
                    type="password"
                    onChange={(e) => {
                        setState((prevState) => ({ ...prevState, confirmNewPassword: e.target.value }))
                    }}
                    inputProps={{
                        style: {
                            boxShadow: "none",
                            width: `calc(100vw - 60px)`,
                        },
                    }}
                    // value={state.soDtKhachLienLac}
                    label="Gõ lại mật khẩu mới"
                />
                {/* <IconButton onClick={handleUpdateSoDienThoaiLienLac} ><SaveIcon></SaveIcon></IconButton> */}
                <div>{state.confirmNewPassword==state.newPassword?"Mật khẩu khớp nhau":"Mật khẩu không khớp nhau, kiểm tra lại"}</div>
                <div className="mt-2 w-full justify-center">
                    <Button variant="contained"
                        onClick={handleChangePassword}
                    >Đổi mật khẩu</Button>
                </div>
            </div>
        </div>
    )
}