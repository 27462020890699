import { Button } from "@mui/material";
import React from "react";
import uuid from "react-uuid";


export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage: "", errorStack: "" };
  }
  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      errorMessage: error.message,
      errorStack: error.stack,
    };
  }
  componentDidCatch(error, errorInfo) {
    // ReportErrorApi.reportError({
    //   userId: localStorage.getItem("userId"),
    //   rowId: uuid(),
    //   errorJson: JSON.stringify({ message: error.message, stack: error.stack }),
    //   windowNavigatorJson: JSON.stringify({
    //     appCodeName: window.navigator.appCodeName,
    //     appName: window.navigator.appName,
    //     appVersion: window.navigator.appVersion,
    //     language: window.navigator.language,
    //     platform: window.navigator.platform,
    //     product: window.navigator.product,
    //     productSub: window.navigator.productSub,
    //     userAgent: window.navigator.userAgent,
    //     vendor: window.navigator.vendor,
    //   }),
    //   time: Date.now(),
    // });
  }
  render() {
    if (this.state.hasError) {
      return (
        <div className="grid w-full h-full content-center">
          <div className="text-center text-red-700 break-all mx-5">
            <h2>
              Lỗi hệ thống! Chúng tôi xin lỗi vì sự cố đáng tiếc này. Vui lòng
              liên hệ với quản trị viên để được hỗ trợ.
            </h2>
          </div>
          <div className="mx-5 break-all">
            <h1>
              <b>
                <u>Chi tiết lỗi:</u>
              </b>
            </h1>
            <h1>{this.state.errorMessage}</h1>
            <h1>{this.state.errorStack}</h1>
          </div>
          <Button onClick={() => {window.location = "/";}}>Quay lại</Button>
        </div>
      );
    }
    return this.props.children;
  }
}
