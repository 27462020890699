import { Box, Button, IconButton, TextField, TextareaAutosize } from "@mui/material";
import React, { useState } from "react";
import SaveIcon from '@mui/icons-material/Save';
import { enqueueSnackbar } from "notistack";
import { capLicenseKey, setPasswordOverride, thanhToanCapDuoi, updateAgentInfo } from "../api/allapi";
import { LoginData } from "../apiDataStruct/apidatastruct";
export default function UpdateAgent(props) {
    const [state, setState] = useState(
        {
            matKhauMoi: "",
            ...props.dailyItem,
            username: props.username,
            password: props.password,
            agentId: props.dailyItem.username,
            soThangThanhToan: 0,
            ghiChuThanhToan: "",
            soKeyCapThem: 0,
        }
    )
    const handleCapLicenseKey = async () => {
        /* .add("username", ADMConfig.user_name)
        .add("password", ADMConfig.pass_word)
        .add("owner", daily.username)
        .add("client_id" , ADMRSAConnection.client_id)
        .add("client_secret" , ADMRSAConnection.client_secret)
        .add("grant_type" , "password")
        //////////////
        .add("total" , daily.so_thang_cap_them.toString())
        .add("IMEI" , "ABCDEF")
        .add("days" , "31") */
        try {

            let payload = {
                username: state.username,
                password: state.password,
                owner: state.agentId,
                client_id: LoginData.client_id,
                client_secret: LoginData.client_secret,
                grant_type: LoginData.grant_type,
                total: state.soKeyCapThem,
                IMEI: "ABCDEF",
                days: 31,

            }
            let result = await capLicenseKey(payload)
            if (result.data) {
                enqueueSnackbar(`${JSON.stringify(result.data.lic)}`, { variant: "success" })
            }
        }
        catch (e) {
            enqueueSnackbar(`Lỗi ${e.message}`, { variant: "error" })
        }
    }
    const handleThanhToan = async () => {
        /* .add("username", ADMConfig.user_name)
        .add("password", ADMConfig.pass_word)
        .add("owner", daily.username)
        .add("client_id" , ADMRSAConnection.client_id)
        .add("client_secret" , ADMRSAConnection.client_secret)
        .add("grant_type" , "password")
        //////////////
        .add("total" , daily.so_thang_cap_them.toString())
        .add("IMEI" , "ABCDEF")
        .add("days" , "31") */
        try {

            let payload = {
                username: state.agentId,
                password: state.password,
                login_username: state.username,
                client_id: LoginData.client_id,
                client_secret: LoginData.client_secret,
                grant_type: LoginData.grant_type,
                agent_name: state.agent_name,
                ghi_chu: state.ghi_chu,
                han_muc: state.han_muc,
                level: state.level,
                so_thang: state.soThangThanhToan,
            }
            let result = await thanhToanCapDuoi(payload)
            if (result.data) {
                enqueueSnackbar(`${result.data.data[0].description}`, { variant: "success" })
            }
        }
        catch (e) {
            enqueueSnackbar(`Lỗi ${e.message}`, { variant: "error" })
        }
    }
    const handleUpdateAgent = async () => {
        try {

            let payload = {
                username: state.agentId,
                password: state.password,
                client_id: LoginData.client_id,
                client_secret: LoginData.client_secret,
                grant_type: LoginData.grant_type,
                agent_name: state.agent_name,
                ghi_chu: state.ghi_chu,
                han_muc: state.han_muc,
                level: state.level,
                license_code: state.license_code,
            }
            let result = await updateAgentInfo(payload)
            if (result.data) {
                enqueueSnackbar(`${result.data.data.thongbao}`, { variant: "success" })
            }
        }
        catch (e) {
            enqueueSnackbar(`Lỗi ${e.message}`, { variant: "error" })
        }
    }
    const handleSetNewPassword = async () => {
        try {

            let payload = {
                username: state.agentId,
                password: state.matKhauMoi,
                confirmPassword: state.matKhauMoi,
                client_id: LoginData.client_id,
                client_secret: LoginData.client_secret,
                grant_type: LoginData.grant_type,
                agent_name: state.agent_name,
                ghi_chu: state.ghi_chu,
                han_muc: state.han_muc,
                level: state.level,
                license_code: state.license_code,
            }
            let result = await setPasswordOverride(payload)
            if (result.data) {
                enqueueSnackbar(`${result.data.errorDescription}`, { variant: "success" })
            }
        }
        catch (e) {
            enqueueSnackbar(`Lỗi ${e.message}`, { variant: "error" })
        }
    }
    return (

        <div className="w-full ">
            <div className="flex flex-col">
                <div className=" font-bold bg-slate-400 mt-2 mb-2">Thông tin chung</div>
                <Box
                    component="form"
                    sx={{
                        '& > :not(style)': { m: 1, width: '20ch' }, 'display': 'flex', 'flex-direction': 'column'
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div>
                        <TextField
                            value={state.agentId}
                            //onChange={(e) => { setState({ ...state, daiLyItem: { ...(state.dailyItem), username: e.target.value } }) }}
                            variant="outlined"
                            inputProps={{
                                style: {
                                    boxShadow: "none",
                                    width: `calc(100vw - 40px)`,
                                },
                            }}
                            label="Username"
                        />
                    </div>
                    <div>
                        <TextField
                            value={state.agent_name}
                            onChange={(e) => { setState({ ...state, agent_name: e.target.value }) }}
                            variant="outlined"
                            inputProps={{
                                style: {
                                    boxShadow: "none",
                                    width: `calc(100vw - 40px)`,
                                },
                            }}
                            label="Tên đại lý"
                        />
                    </div>
                    <div className="flex flex-row w-6/12">
                        <div className="w-[80%]">
                            <TextField
                                value={state.matKhauMoi}
                                onChange={(e) => { setState({ ...state, matKhauMoi: e.target.value }) }}
                                variant="outlined"


                                label="Mật khẩu mới"
                            />

                        </div>
                        <div className="w-[20%]">
                            {<IconButton sx={{ top: '10px', }}
                                onClick={handleSetNewPassword}
                            ><SaveIcon></SaveIcon></IconButton>}
                        </div>
                    </div>
                    <div>
                        <TextField
                            value={state.ghi_chu}
                            onChange={(e) => { setState({ ...state, ghi_chu: e.target.value }) }}
                            variant="outlined"
                            inputProps={{
                                style: {

                                    width: `calc(100vw - 40px)`,
                                },
                            }}
                            label="Ghi chú"
                        />
                    </div>
                    <div>
                        <TextField
                            value={state.han_muc}
                            onChange={(e) => { setState({ ...state, han_muc: e.target.value }) }}
                            variant="outlined"
                            inputProps={{
                                style: {
                                    boxShadow: "none",
                                    width: `calc(100vw - 40px)`,
                                },
                            }}
                            label="Hạn mức"
                        />
                    </div>
                    <div>
                        <TextField
                            value={state.da_cap}

                            variant="outlined"
                            inputProps={{
                                style: {
                                    boxShadow: "none",
                                    width: `calc(100vw - 40px)`,
                                },
                            }}
                            label="Đã cấp"
                        />
                    </div>
                    <div>
                        <TextField
                            value={state.level == 1 ? "Tài khoản admin" : state.level == 2 ? "Tài khoản tổng" : "Tài khoản đại lý"}

                            variant="outlined"
                            inputProps={{
                                style: {
                                    boxShadow: "none",
                                    width: `calc(100vw - 40px)`,
                                },
                            }}
                            label="Loại tài khoản"
                        />
                    </div>
                    <div>
                        <TextareaAutosize
                            value={state.license_code}
                            onChange={(e) => { setState({ ...state, license_code: e.target.value }) }}
                            placeholder='Điền thông tin liên lạc đầu trên ở đây'
                            style={{
                                boxShadow: "none",
                                width: `calc(100vw - 40px)`,
                                color:'black',
                            }}
                            
                            label="Nội dung thông báo"></TextareaAutosize>
                    </div>
                </Box>
                <div className="mt-2">
                    <Button variant="contained"
                        onClick={handleUpdateAgent}
                    >Lưu</Button>
                </div>
            </div>
            <div className="flex flex-col">
                <div className=" font-bold bg-slate-400 mt-2 mb-2">Thanh toán</div>
                <Box
                    component="form"
                    sx={{
                        '& > :not(style)': { m: 1, width: '18ch' }, 'display': 'flex', 'flex-direction': 'column'
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div>
                        <TextField
                            value={state.soThangThanhToan}
                            onChange={(e) => { setState({ ...state, soThangThanhToan: e.target.value }) }}
                            variant="outlined"
                            inputProps={{
                                style: {
                                    boxShadow: "none",
                                    width: `calc(100vw - 40px)`,
                                },
                            }}
                            label="Số tháng thanh toán"
                        />
                    </div>
                    <div>
                        <TextField
                            value={state.ghiChuThanhToan}
                            onChange={(e) => { setState({ ...state, ghiChuThanhToan: e.target.value }) }}
                            variant="outlined"
                            inputProps={{
                                style: {
                                    boxShadow: "none",
                                    width: `calc(100vw - 40px)`,
                                },
                            }}
                            label="Ghi chú"
                        />
                    </div>
                </Box>
                <div className="mt-2 w-full">
                    <Button variant="contained"
                        onClick={handleThanhToan}
                    >Thanh toán</Button>
                </div>
            </div>
            {state.level == 2 &&
                <div className="flex flex-col">
                    <div className=" font-bold bg-slate-400 mt-2 mb-2">Cấp thêm licsenkey</div>
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '20ch' }, 'display': 'flex', 'flex-direction': 'column'
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextField

                                value={state.soKeyCapThem}
                                onChange={(e) => { setState({ ...state, soKeyCapThem: e.target.value }) }}
                                variant="outlined"
                                inputProps={{
                                    style: {
                                        boxShadow: "none",
                                        width: `calc(100vw - 40px)`,
                                    },
                                }}
                                label="Số key cấp thêm"
                            />
                        </div>
                    </Box>
                    <div className="mt-2 w-full">
                        <Button variant="contained"
                            onClick={handleCapLicenseKey}
                        >Cấp Licsenkey</Button>
                    </div>
                </div>
            }
        </div>
    )
}