import { getLinearProgressUtilityClass } from "@mui/material"
import { LoginData, LoginDataResponse } from "../apiDataStruct/apidatastruct"
import ApiHelper from "./ApiHelper"
const login = async (loginData) => {
    let response;
    try {
        response = await ApiHelper.post("/auth2", loginData);
        //response = await ApiHelper.get("/",{ baseURL: "https://vnexpress.net" }) ;
        //response = await ApiHelper.get("/swagger-ui.html",{ baseURL: "https://ilottery.win/api" }) ;
        //console.log(response)
    }
    catch (e) {
        return e;
    }
    return response;
}
const getFullInfoByImeiAndOtp = async (payload) => {
    let response;
    try {
        response = await ApiHelper.post("/capquyen/fullinfo/", payload);

    }
    catch (e) {
        return e;
    }
    return response;
}
const getSoDienThoaiLienLac = async (payload) => {
    let response;
    try {
        response = await ApiHelper.get(`/license/getAgentPhoneForMan?username=${payload.username}`);

    }
    catch (e) {
        return e;
    }
    return response;
}
const getSoDienThoaiLienLacCapTren = async (payload) => {
    let response;
    try {
        response = await ApiHelper.get(`/license/getAgentPhoneBoss?username=${payload.username}`);

    }
    catch (e) {
        return e;
    }
    return response;
}
const getAgentInfo = async (payload) => {
    let response;
    try {
        response = await ApiHelper.post(`/capquyen/userinfo/`, payload);

    }
    catch (e) {
        return e;
    }
    return response;
}
const capQuyen = async (payload) => {
    let response;
    try {
        response = await ApiHelper.post(`/capquyen/capquyen/`, payload);

    }
    catch (e) {
        return e;
    }
    return response;
}
const huyThang = async (payload) => {
    let response;
    try {
        response = await ApiHelper.post(`/capquyen/huythang/`, payload);

    }
    catch (e) {
        return e;
    }
    return response;
}
const sendFCMToUser = async (payload) => {
    let response;
    try {
        response = await ApiHelper.post(`/msg/sendmsgtouser`, payload);

    }
    catch (e) {
        return e;
    }
    return response;
}
const updateSoDienThoaiLienLac = async (payload) => {
    let response;
    try {
        response = await ApiHelper.post(`/license/updateSoDienThoaiLienLac`, payload);

    }
    catch (e) {
        return e;
    }
    return response;
}

const getDanhSachDaiLy = async (payload) => {
    let response;
    try {
        response = await ApiHelper.post(`/capquyen/dsdaily/`, payload);

    }
    catch (e) {
        return e;
    }
    return response;
}
const updateAgentInfo = async (payload) => {
    let response;
    try {
        response = await ApiHelper.post(`/capquyen/updatedaily/`, payload);

    }
    catch (e) {
        return e;
    }
    return response;
}
const setPasswordOverride = async (payload) => {
    let response;
    try {
        response = await ApiHelper.post(`/setpassword/`, payload);

    }
    catch (e) {
        return e;
    }
    return response;
}


const thanhToanCapDuoi = async (payload) => {
    let response;
    try {
        response = await ApiHelper.post(`/capquyen/thanhtoan/`, payload);

    }
    catch (e) {
        return e;
    }
    return response;
}
const capLicenseKey = async (payload) => {
    let response;
    try {
        response = await ApiHelper.post(`createlic`, payload);

    }
    catch (e) {
        return e;
    }
    return response;
}
const reportBaoCaoCapquyen = async (payload) => {
    let response;
    try {
        response = await ApiHelper.post(`report/capquyen/`, payload);
    }
    catch (e) {
        return e;
    }
    return response;
}
const reportThueBaoSapHetHan = async (payload) => {
    let response;
    try {
        response = await ApiHelper.post(`report/saphethan/`, payload);
    }
    catch (e) {
        return e;
    }
    return response;
}
const reportThueBaoDangHoatDong = async (payload) => {
    let response;
    try {
        response = await ApiHelper.post(`report/danghoatdong/`, payload);
    }
    catch (e) {
        return e;
    }
    return response;
}
const reportThueBaoNgungHoatDong = async (payload) => {
    let response;
    try {
        response = await ApiHelper.post(`report/ngunghoatdong/`, payload);
    }
    catch (e) {
        return e;
    }
    return response;
}
const reportMayHuy = async (payload) => {
    let response;
    try {
        response = await ApiHelper.post(`report/huythang/`, payload);
    }
    catch (e) {
        return e;
    }
    return response;
}
const reportThanhToanCapDuoi = async (payload) => {
    let response;
    try {
        response = await ApiHelper.post(`report/thanhtoan/`, payload);
    }
    catch (e) {
        return e;
    }
    return response;
}
const reportThanhToanAdmin = async (payload) => {
    let response;
    try {
        response = await ApiHelper.post(`report/thanhtoanadmin/`, payload);
    }
    catch (e) {
        return e;
    }
    return response;
}
const reportBanHang = async (payload) => {
    let response;
    try {
        response = await ApiHelper.post(`report/banhang/`, payload);
    }
    catch (e) {
        return e;
    }
    return response;
}
const reportTraCuuImei = async (payload) => {
    let response;
    try {
        response = await ApiHelper.post(`report/tracuuimei/`, payload);
    }
    catch (e) {
        return e;
    }
    return response;
}
const changePassword = async (payload) => {
    let response;
    try {
        response = await ApiHelper.post(`changepassword/`, payload);
    }
    catch (e) {
        return e;
    }
    return response;
}
const registerUser = async (payload) => {
    let response;
    try {
        response = await ApiHelper.post(`register/`, payload);
    }
    catch (e) {
        return e;
    }
    return response;
}
export {
    login, getFullInfoByImeiAndOtp, getSoDienThoaiLienLac, getSoDienThoaiLienLacCapTren, getAgentInfo, capQuyen, huyThang, sendFCMToUser,
    updateSoDienThoaiLienLac, getDanhSachDaiLy, updateAgentInfo, setPasswordOverride, thanhToanCapDuoi, capLicenseKey
    , reportBaoCaoCapquyen, reportThueBaoSapHetHan, reportThueBaoDangHoatDong,reportThueBaoNgungHoatDong,
    reportMayHuy,reportThanhToanCapDuoi,reportThanhToanAdmin,reportBanHang,reportTraCuuImei,
    changePassword,registerUser
}