import React, { useEffect, useState } from "react";
import { getDanhSachDaiLy } from "../api/allapi";
import { LoginData } from "../apiDataStruct/apidatastruct";
import { CircularProgress, IconButton, Stack } from "@mui/material";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
export default function DanhSachDaiLy(props) {

    const [state, setState] = useState({
        isLoading: true,
        danhSachDaiLy: [],
    });

    useEffect(() => {
        let payload = {
            username: props.username,
            password: props.password,
            client_id: LoginData.client_id,
            client_secret: LoginData.client_secret,
            grant_type: LoginData.grant_type,
            level: 0,
        }
        const fetchData = async () => {
            let result = await getDanhSachDaiLy(payload);
            setState({ ...state, danhSachDaiLy: result.data.data, isLoading: false })
        }
        fetchData();
    }, [])
    const classNameNOrmal="flex flex-row h-10 border-spacing-1  justify-between";
    const classNameTong="flex flex-row h-10 border-spacing-1  justify-between  bg-red-300";
    return (
        <div className=" w-full text-left px-5 relative">
            {state.isLoading &&
                <div className="w-full absolute left-[45%]" ><Stack sx={{ color: 'grey.500' }} >

                    <CircularProgress color="inherit" />

                </Stack>
                </div>
            }
            {
                !state.isLoading && (
                    state.danhSachDaiLy.filter(value => {
                        if (value.username.toLowerCase().includes(props.textFilter.toLowerCase()))
                            return true;
                        else
                            return false;
                    })
                        .map((item, index) => {
                            return (
                                <div key={`dsdl${index}`} className={item.level==2&&item.parent_username!=""?classNameTong:classNameNOrmal}>
                                    
                                    <div className=" text-right px-5">{index + 1} </div>
                                    <div className=" text-left justify-start "> {item.username} ({item.da_cap}/{item.han_muc}) </div>
                                    <div >
                                        <IconButton  
                                            onClick={()=>{props.onChangeToUpdatAgentScreen(item)}}
                                        ><RestartAltIcon></RestartAltIcon></IconButton>
                                        {/* <IconButton ><AddIcon></AddIcon></IconButton> */}
                                    </div>
                                </div>
                            )
                        })
                )
            }
        </div>
    )
}