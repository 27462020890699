import React, { useEffect, useState } from "react";
import './capquyen.css'
import { Button, IconButton, TextField } from "@mui/material";
import TextareaAutosize from '@mui/base/TextareaAutosize';
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';

import {  capQuyen, getAgentInfo, getFullInfoByImeiAndOtp, getSoDienThoaiLienLac, getSoDienThoaiLienLacCapTren, huyThang, sendFCMToUser, updateSoDienThoaiLienLac } from "../api/allapi";
import { enqueueSnackbar } from "notistack";
import { LoginData } from "../apiDataStruct/apidatastruct";
export default function CapQuyen(props) {
    const [state, setState] = useState({
        imei: "",
        soThangThem: "",
        maDk: "",
        ghiChu: "",
        soDtKhachLienLac: "",
        soDtLienLacCapTren: "",
        noiDungThongBao: "",
        username: props.username,
        agentInfo: "",
        userInfoText: ""

    })
    const handleCapQuyen = async () => {
        try {
            let capQuyenData = {
                username: state.username, password: props.password,
                imei_khach: state.imei,
                so_thang: state.soThangThem,
                ghi_chu: state.ghiChu,
                ma_dang_ky: state.maDk,
                client_id: LoginData.client_id,
                client_secret: LoginData.client_secret,
                grant_type: LoginData.grant_type
            }
            let result = await capQuyen(capQuyenData)
            if (result.status && result.status == 401) {
                enqueueSnackbar(
                    `Sai tên truy cập hoặc mật khẩu`,
                    {
                        variant: "error",
                    }
                );
            }
            else {
                enqueueSnackbar(
                    `${result.data.data[0].description}`,
                    {
                        variant: "info",
                    }
                );
            }
        }
        catch (e) {
            enqueueSnackbar(
                `Lỗi ${e.message}`,
                {
                    variant: "error",
                }
            );
        }
    }
    const handleHuyThang = async () => {
        try {
            let capQuyenData = {
                username: state.username, password: props.password,
                imei: state.imei,
                so_thang: state.soThangThem,
                client_id: LoginData.client_id,
                client_secret: LoginData.client_secret,
                grant_type: LoginData.grant_type
            }
            let result = await huyThang(capQuyenData)
            if (result.status && result.status == 401) {
                enqueueSnackbar(
                    `Sai tên truy cập hoặc mật khẩu`,
                    {
                        variant: "error",
                    }
                );
            }
            else {
                enqueueSnackbar(
                    `${result.data.data[0].thongbao}`,
                    {
                        variant: "info",
                    }
                );
            }
        }
        catch (e) {
            enqueueSnackbar(
                `Lỗi ${e.message}`,
                {
                    variant: "error",
                }
            );
        }
    }
    const handleActiveLd = async () => {
        try {
            let capQuyenData = {
                username: state.username,
                password: props.password,
                to_imei: state.imei,
                type: "activeld",
                message: state.thongbao,
                so_thang: state.soThangThem,
                client_id: LoginData.client_id,
                client_secret: LoginData.client_secret,
                grant_type: LoginData.grant_type
            }
            let result = await sendFCMToUser(capQuyenData)
            if (result.status && result.status == 401) {
                enqueueSnackbar(
                    `Sai tên truy cập hoặc mật khẩu`,
                    {
                        variant: "error",
                    }
                );
            }
            else {
                let success=JSON.parse(result.data.data).success;
                let message=success=="1"?"Gửi thành công:":"Gửi thất bại:"
                enqueueSnackbar(
                    `${message} ${state.thongbao}`,
                    {
                        variant: "info",
                    }
                );
            }
        }
        catch (e) {
            enqueueSnackbar(
                `Lỗi ${e.message}`,
                {
                    variant: "error",
                }
            );
        }
    }
    const handleSendMessageToUser = async () => {
        try {
            let capQuyenData = {
                username: state.username,
                password: props.password,
                to_imei: state.imei,
                type: "notification",
                message: state.thongbao,
                so_thang: state.soThangThem,
                client_id: LoginData.client_id,
                client_secret: LoginData.client_secret,
                grant_type: LoginData.grant_type
            }
            let result = await sendFCMToUser(capQuyenData)
            if (result.status && result.status == 401) {
                enqueueSnackbar(
                    `Sai tên truy cập hoặc mật khẩu`,
                    {
                        variant: "error",
                    }
                );
            }
            else {
                let success=JSON.parse(result.data.data).success;
                let message=success=="1"?"Gửi thành công:":"Gửi thất bại:"
                enqueueSnackbar(
                    `${message} ${state.thongbao}`,
                    {
                        variant: "info",
                    }
                );
            }
        }
        catch (e) {
            enqueueSnackbar(
                `Lỗi ${e.message}`,
                {
                    variant: "error",
                }
            );
        }
    }
    const handleGetFullInfo = async () => {
        try {
            let payload = { imei_khach: state.imei, madk: state.maDk, username: state.username }
            let result = await getFullInfoByImeiAndOtp(payload)
            let data = result.data.data
            let userInforText = "Hạn sd:" + data.kqxs_998;
            setState({ ...state, imei: data.imei ? data.imei : "", ghiChu: data.ghi_chu, userInfoText: userInforText })
        }
        catch (e) {
            enqueueSnackbar(
                `Lỗi ` + e.message,
                {
                    variant: "error",
                }
            )
        }
    }
    const handleUpdateSoDienThoaiLienLac = async () => {
        try {
            let capQuyenData = {
                username: state.username, password: props.password,
                imei: state.imei,
                so_thang: state.soThangThem,
                phone: state.soDtKhachLienLac,
                ma_dang_ky: state.maDk,
                client_id: LoginData.client_id,
                client_secret: LoginData.client_secret,
                grant_type: LoginData.grant_type
            }
            let result = await updateSoDienThoaiLienLac(capQuyenData)
            if (result.status && result.status == 401) {
                enqueueSnackbar(
                    `Sai tên truy cập hoặc mật khẩu`,
                    {
                        variant: "error",
                    }
                );
            }
            else {
                let data=result.data.ghi_chu
                enqueueSnackbar(
                    `Đã cập nhật ${data}`,
                    {
                        variant: "info",
                    }
                );
            }
        }
        catch (e) {
            enqueueSnackbar(
                `Lỗi ${e.message}`,
                {
                    variant: "error",
                }
            );
        }
    }

    useEffect(() => {
        const fetchdata = async () => {
            try {
                let result = await getAgentInfo({ username: props.username });

                //setState({ ...state, soDtKhachLienLac: result.data.data[0].email })
                let agentInfo = result.data.data[0]
                let agetnInforText = `${agentInfo.username} đã cấp ${agentInfo.da_cap} /${agentInfo.han_muc}`
                setState((prevState) => { return { ...prevState, agentInfo: agetnInforText }});
            }
            catch (e) {
                enqueueSnackbar(`Lỗi ${e.message}`, { variant: "error" })
            }
            try {
                let result = await getSoDienThoaiLienLac({ username: props.username });

                //setState({ ...state, soDtKhachLienLac: result.data.data[0].email })
                setState((prevState) => ({ ...prevState, soDtKhachLienLac: result.data.data[0].email }));

            }
            catch (e) {
                enqueueSnackbar(`Lỗi ${e.message}`, { variant: "error" })
            }
            try {
                let result = await getSoDienThoaiLienLacCapTren({ username: props.username });

                //setState({ ...state, soDtLienLacCapTren: result.data.data[0].first_name })
                setState((prevState) => ({
                    ...prevState,
                    soDtLienLacCapTren: result.data.data[0].first_name,
                    // Bao gồm lại các state cần giữ nguyên
                    //soDtKhachLienLac: prevState.soDtKhachLienLac
                }));

            }
            catch (e) {
                enqueueSnackbar(`Lỗi ${e.message}`, { variant: "error" })
            }
        }
        fetchdata()
        return () => { }
    }, [])


    return (
        <div className="capquyen-root">
            <div className=" text-left">
                <div>{state.agentInfo}</div>
                <TextField
                    value={state.imei}
                    variant="standard"
                    inputProps={{
                        style: {
                            boxShadow: "none",
                            width: `calc(100vw - 60px)`,
                        },
                    }}
                    label="imei"
                    onChange={(e) => { setState({ ...state, imei: e.target.value }) }}
                />
                <IconButton onClick={handleGetFullInfo} ><SearchIcon></SearchIcon></IconButton>
            </div>
            <div>{state.userInfoText}</div>
            <div className=" text-left">
                <TextField
                    value={state.soThangThem}
                    onChange={(e) => { setState({ ...state, soThangThem: e.target.value }) }}
                    variant="standard"
                    inputProps={{
                        style: {
                            boxShadow: "none",
                            width: `calc(100vw - 40px)`,
                        },
                    }}
                    label="số tháng thêm"
                />
            </div>
            <div className=" text-left">
                <TextField
                    value={state.maDk}
                    onChange={(e) => { setState({ ...state, maDk: e.target.value }) }}
                    variant="standard"
                    inputProps={{
                        style: {
                            boxShadow: "none",
                            width: `calc(100vw - 40px)`,
                        },
                    }}
                    label="Mã đăng ký"
                />
            </div>
            <div className=" text-left">
                <TextField
                    value={state.ghiChu}
                    onChange={(e) => { setState({ ...state, ghiChu: e.target.value }) }}
                    variant="standard"
                    inputProps={{
                        style: {
                            boxShadow: "none",
                            width: `calc(100vw - 40px)`,

                        },
                    }}
                    label="Ghi chú"
                />
            </div>
            <div className=" text-left">
                <TextField

                    onChange={(e) => { setState({ ...state, soDtKhachLienLac: e.target.value }) }}
                    variant="standard"
                    inputProps={{
                        style: {
                            boxShadow: "none",
                            width: `calc(100vw - 60px)`,
                        },
                    }}
                    value={state.soDtKhachLienLac}
                    label="Số ĐT cho khách liên lạc"
                />
                <IconButton onClick={handleUpdateSoDienThoaiLienLac} ><SaveIcon></SaveIcon></IconButton>
            </div>
           
            <div>
                <TextareaAutosize
                    value={state.thongbao}
                    placeholder='Gõ nội dung thông báo vào đây'
                    style={{
                        boxShadow: "none",
                        width: `calc(100vw - 40px)`,
                    }}
                    onChange={(e) => { setState({ ...state, thongbao: e.target.value }) }}
                    label="Nội dung thông báo"></TextareaAutosize>
            </div>
            <div className=" justify-start w-full">
                <Button variant="outlined"
                    onClick={handleCapQuyen}
                >Thêm tháng</Button>
                <Button variant="outlined"
                    onClick={handleHuyThang}>Huỷ tháng</Button>
            </div>
            <div className=" justify-between w-full">
                <Button variant="outlined"
                    onClick={handleActiveLd}
                >Mở mạng</Button>
                <Button variant="outlined"
                    onClick={handleSendMessageToUser}
                >Gửi thông báo</Button>
            </div>
            <div className= " text-red-500">{state.soDtLienLacCapTren}</div>
        </div>

    )
}