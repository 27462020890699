import logo from './logo.svg';
import './App.css';
import { Badge, Button, IconButton, InputBase, Menu, MenuItem, Paper, Snackbar, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { login } from './api/allapi';
import { LoginData } from './apiDataStruct/apidatastruct';
import CapQuyen from './components/capquyen';
import { ScreenEnum } from './components/screenSetting.ts';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DanhSachDaiLy from './components/danhsachdaily';
import { Search } from '@mui/icons-material';
import { styled, alpha } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import './SearchInput.css'
import UpdateAgent from './components/updateAgent';
import Report from './components/report';
import ChangePassword from './components/changepasswrord';
import CreateAgent from './components/createagent';
function App() {
  const { enqueueSnackbar } = useSnackbar();
  const handleLogin = async () => {
    try {
      let loginData = { ...LoginData, username: state.username, password: state.password }
      let result = await login(loginData)
      if (result.status && result.status == 401) {
        enqueueSnackbar(
          `Sai tên truy cập hoặc mật khẩu`,
          {
            variant: "error",
          }
        );
      }
      else {
        localStorage["access_token"] = result.data.access_token
        setState({ ...state, isLogin: true })
        enqueueSnackbar(
          `Đăng nhập thành công`,
          {
            variant: "success",
          }
        );
      }
    }
    catch (e) {
      enqueueSnackbar(
        `Lỗi ${e.message}`,
        {
          variant: "error",
        }
      );
    }
  }

  useEffect(() => {
    enqueueSnackbar(
      `Chào mừng bạn.`,
      {
        variant: "success",
      }
    );
  }, [])
  const [state, setState] = useState({
    username: "", password: "", isLogin: false,
    currentScreen: ScreenEnum.CapQuyenScreen,
    anchorEl: false,
    textFilter: "",
    dailyItem: []
  });
  const handleChangeUsername = (e) => {
    setState({ ...state, username: e.target.value.toLowerCase() })
  }
  const handleChanePassword = (e) => {
    setState({ ...state, password: e.target.value })
  }
  const openMenu = (event) => {
    setState({ ...state, anchorEl: event.currentTarget });
  };
  const closeMenu = function (e) {
    setState({ ...state, anchorEl: false });
  };
  const handleSearchChange = function (e) {
    //console.log(`Search changed: ${e.target.value}`);
    setState({
      ...state,
      textFilter: e.target.value,
    });
  };
  const onChangeToUpdatAgentScreen = (userItem) => {
    setState({ ...state, currentScreen: ScreenEnum.UpdateAgentScreen, dailyItem: userItem })
    console.log(userItem)
  }

  return (
    <div>
      {state.isLogin &&
        <div className='fixed top-0 z-50'>
          <IconButton
            sx={{ height: 40, marginRight: 2 }}
            aria-owns={state.anchorEl ? "simple-menu" : null}
            aria-haspopup="true"
            aria-label="Menu"
            onClick={openMenu}
          >
            <Badge
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              //badgeContent={props.selectedCount}
              color="primary"
            >
              <MoreVertIcon />
            </Badge>
          </IconButton>
        </div>
      }
      {<div className='flex flex-row relative mt-10 '>

        {state.currentScreen == ScreenEnum.DanhSachDaiLyScreen &&
          <div className="search-input-wrapper">
            <SearchIcon className="search-input-icon" />
            <input
              className="border-hidden bg-transparent focus:border-transparent focus:ring-0"
              type="search"
              autoComplete="off"
              value={state.textFilter}
              onChange={handleSearchChange}
              id="search-inputbox"
              placeholder="Tìm kiếm"
            />
          </div>}

      </div>}


      <div className="App">
        {/* <header className="App-header"> */}

        <Menu
          id="congno-menu"
          anchorEl={state.anchorEl}
          open={Boolean(state.anchorEl)}
          onClose={() => { setState({ ...state, anchorEl: false }); }}
          getcontentanchorel={null}
          disableAutoFocusItem
          disableRestoreFocus={true}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <MenuItem onClick={() => {
            closeMenu()
            setState((prevState) => ({ ...prevState, currentScreen: ScreenEnum.CapQuyenScreen }))
          }}>Gia hạn sử dụng</MenuItem>
          <MenuItem onClick={() => {
            closeMenu()
            setState((prevState) => ({ ...prevState, currentScreen: ScreenEnum.DanhSachDaiLyScreen }))
          }}>Danh sách đại lý</MenuItem>
          <MenuItem onClick={() => {
            closeMenu()
            setState((prevState) => ({ ...prevState, currentScreen: ScreenEnum.Report }))
          }}>Báo cáo</MenuItem>
          <MenuItem onClick={() => {
            closeMenu()
            setState((prevState) => ({ ...prevState, currentScreen: ScreenEnum.CreateAgent }))
          }}>Tạo đại lý</MenuItem>
          <MenuItem onClick={() => {
            closeMenu()
            setState((prevState) => ({ ...prevState, currentScreen: ScreenEnum.ChangePassword }))
          }}>Đổi mật khẩu</MenuItem>
        </Menu>
        <Paper>
          <div className=' justify-col '>
            {!state.isLogin && <div >
              <div className=' absolute top-1/3 flex flex-col m-4 p-0 w-ful left-[20%]'>
                <TextField value={state.username}
                  onChange={handleChangeUsername}
                  variant="standard"
                  label="username"

                  inputProps={{
                    style: {
                      boxShadow: "none",
                      width: "100%",
                      
                    },

                  }}>

                </TextField>

                <TextField value={state.password}
                  onChange={handleChanePassword}
                  variant="standard"
                  type='password'
                  label="password"
                  /*  sx={{ fontSize: "14px" }} */

                  inputProps={{
                    style: {
                      boxShadow: "none",
                    },
                  }}>

                </TextField>
                <div>
                  <Button onClick={handleLogin}>Đăng nhập</Button>
                </div>
              </div>

            </div>}
            {state.isLogin && state.currentScreen == ScreenEnum.CapQuyenScreen && <CapQuyen username={state.username} password={state.password} />}
            {state.isLogin && state.currentScreen == ScreenEnum.DanhSachDaiLyScreen
              && <DanhSachDaiLy
                textFilter={state.textFilter}
                username={state.username}
                password={state.password}
                onChangeToUpdatAgentScreen={onChangeToUpdatAgentScreen}
                rootScreen={state.currentScreen}
              />}
            {state.isLogin && state.currentScreen == ScreenEnum.UpdateAgentScreen
              && <UpdateAgent dailyItem={state.dailyItem} username={state.username} password={state.password}></UpdateAgent>
            }
            {state.isLogin && state.currentScreen == ScreenEnum.Report
              && <Report  username={state.username} password={state.password}></Report>
            }
            {state.isLogin && state.currentScreen == ScreenEnum.ChangePassword
              && <ChangePassword  username={state.username} password={state.password}></ChangePassword>
            }
            {state.isLogin && state.currentScreen == ScreenEnum.CreateAgent
              && <CreateAgent  username={state.username} password={state.password}></CreateAgent>
            }
          </div>

        </Paper>

        {/* </header> */}

      </div>
    </div>
  );
}

export default App;
