import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { LoginData } from "../apiDataStruct/apidatastruct";
import { registerUser } from "../api/allapi";
import { enqueueSnackbar } from "notistack";
export default function CreateAgent(props) {
    /*
    .add("username", daily.username)
    .add("password", daily.create_pass)
    .add("client_id" , ADMRSAConnection.client_id)
    .add("client_secret" , ADMRSAConnection.client_secret)
    .add("grant_type" , "password")

    .add("ghi_chu" , daily.ghi_chu )
    .add("han_muc" , daily.han_muc.toString() )
    .add("agent_name" , daily.agent_name )
    .add("level" , daily.level.toString() )
    .add("parent_username" , daily.parent_username )*/
    const [state, setState] = useState(
        {
            username: "",
            password: "",
            ghiChu: "",
            hanMuc: 0,
            agentName: "",
            level: 0,// fix lai may chu, tu tinh
            parentUsername: "" // fix lai may chu, tu tinh
        }
    );
   
    const handleCreateAgent = async () => {
        const payload = {
            username: state.username,
            password: state.password,
            client_id: LoginData.client_id,
            client_secret: LoginData.client_secret,
            grant_type: LoginData.grant_type,
            ghi_chu: state.ghiChu,
            han_muc: state.hanMuc,
            agent_name: state.agentName,
            level: state.level,
            parent_username: props.username
        }
        try {
            let result = await registerUser(payload)
            enqueueSnackbar(`${result.data.errorDescription}`, { variant: result.data.error==1?"error":"success" })
        }
        catch (e) {
            enqueueSnackbar(`Lỗi ${e.message}`, { variant: "error" })
        }

    }
    return (
        <div>

            <div className=" w-full">
                <TextField
                    variant="standard"
                    onChange={(e) => {
                        setState((prevState) => ({ ...prevState, username: e.target.value }))
                    }}
                    inputProps={{
                        style: {
                            boxShadow: "none",
                            width: `calc(100vw - 60px)`,
                        },
                    }}
                    value={state.username}
                    label="Tên đăng nhập (username)"
                />
                <TextField
                    variant="standard"
                    onChange={(e) => {
                        setState((prevState) => ({ ...prevState, password: e.target.value }))
                    }}
                    inputProps={{
                        style: {
                            boxShadow: "none",
                            width: `calc(100vw - 60px)`,
                        },
                    }}
                    value={state.password}
                    label="Mật khẩu"
                />
                <TextField
                    variant="standard"

                    onChange={(e) => {
                        setState((prevState) => ({ ...prevState, hanMuc: e.target.value }))
                    }}
                    inputProps={{
                        style: {
                            boxShadow: "none",
                            width: `calc(100vw - 60px)`,
                        },
                    }}
                    value={state.hanMuc}
                    label="Hạn mức tháng"
                />
                <TextField
                    variant="standard"
                    onChange={(e) => {
                        setState((prevState) => ({ ...prevState, agentName: e.target.value }))
                    }}
                    inputProps={{
                        style: {
                            boxShadow: "none",
                            width: `calc(100vw - 60px)`,
                        },
                    }}
                    value={state.agentName}
                    label="Tên đại lý"
                />
                <TextField
                    variant="standard"
                    onChange={(e) => {
                        setState((prevState) => ({ ...prevState, ghiChu: e.target.value }))
                    }}
                    inputProps={{
                        style: {
                            boxShadow: "none",
                            width: `calc(100vw - 60px)`,
                        },
                    }}
                    value={state.ghiChu}
                    label="Ghi chú"
                />
                {/* <IconButton onClick={handleUpdateSoDienThoaiLienLac} ><SaveIcon></SaveIcon></IconButton> */}

                <div className="mt-2 w-full justify-center">
                    <Button variant="contained"
                        onClick={handleCreateAgent}
                    >Tạo người dùng</Button>
                </div>
            </div>
        </div>
    )
}