import axiosClient from "./axiosClient";

export const getHeader = function () {
  return {
    "content-type": "application/json",
    Authorization: `bearer ${localStorage["access_token"]}`,
  };
};
const refreshToken = async function () {
  try {
    /* let last_update = Number(localStorage["last_update"]); // timestamp in milliseconds
    let now = Date.now();
    let expires_in = Number(localStorage["expires_in"]);
    let timeRemain = expires_in * 1000 + last_update - now;
    if (timeRemain < 60000) {
      // còn 1 phút thì call refresh token
      axiosClient
        .post("/refreshtoken", {
          refresh_token: localStorage["refresh_token"],
        })
        .then((response) => {
          localStorage["access_token"] = response.data.access_token;
          localStorage["refresh_token"] = response.data.refresh_token;
          localStorage["expires_in"] = response.data.expires_in;
          localStorage["last_update"] = Date.now();
        });
    } */
  } catch (e) {
    throw e;
  }
};
const ApiHelper = {
  post: async function (url, data, cfg) {
    let config = { headers: getHeader() };
    if (cfg) {
      config = { ...config, ...cfg };
    }
    await refreshToken();
    let result = {};
    try {
      result = await axiosClient.post(url, data, config);
      return result;
    } catch (e) {
      if (e.request.status==401){
        return e.response
      }
      else
        throw e;
    
    }
  },
  get: async (url, cfg) => {
    try {
      url = url.replace("+", "%2B");
      await refreshToken();
      let result
      if (cfg == undefined)
        result = await axiosClient.get(url, { headers: getHeader() });
      else
        result = await axiosClient.get(url, { headers: getHeader(), ...cfg });
      return result;
    } catch (e) {
      
        throw e;
      
    }
  },
  delete: async (url) => {
    try {
      await refreshToken();
      return await axiosClient.delete(url, { headers: getHeader() });
    } catch (e) {
      if (e.response.status === 401) {
        window.location = "/";
      } else {
        throw e;
      }
    }
  },
};
export default ApiHelper;
