 const LoginData ={
    client_id: "turkey-api-2018",
    client_secret: "VHVya2V5IEFQSSBpcyBub3QgaW5mZXJpb3I=",
    grant_type: "password",
    username: "publicuser",
    password: "123456@#$"
  }
  const LoginDataResponse ={
    "access_token": "0c57518c32d4defec2860a295ceffbfdc2ee81a5",
    "expires_in": 3600,
    "token_type": "Bearer",
    "scope": null,
    "refresh_token": "e4272927219dbd1ea1b5e14033005729435230b3"
}
export {LoginData,LoginDataResponse};