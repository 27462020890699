import { Box, Button, CircularProgress, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, MobileDatePicker } from "@mui/x-date-pickers";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import 'dayjs/locale/vi';
import dayjs from 'dayjs';
import { enqueueSnackbar } from "notistack";
import ApiHelper from "../api/ApiHelper";
import { reportBanHang, reportBaoCaoCapquyen, reportMayHuy, reportThanhToanAdmin, reportThanhToanCapDuoi, reportThueBaoDangHoatDong, reportThueBaoNgungHoatDong, reportThueBaoSapHetHan, reportTraCuuImei } from "../api/allapi";
export default function Report(props) {
    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }
    const ReportList = {
        CapQuyen: { key: "CapQuyen", title: "Báo cáo cấp quyền" },
        SapHetHan: { key: "SapHetHan", title: "Máy sắp hết hạn" },
        DangHoatDong: { key: "DangHoatDong", title: "Máy đang hoạt động" },
        NgungHoatDong: { key: "NgungHoatDong", title: "Máy ngừng hoạt động" },
        MayHuy: { key: "MayHuy", title: "Máy huỷ" },
        ThanhToanCapDuoi: { key: "ThanhToanCapDuoi", title: "Thanh toán cấp dưới" },
        ThanhToanCapTren: { key: "ThanhToanCapTren", title: "Thanh toán cấp trên" },
        BanHang: { key: "BanHang", title: "Bán hàng" },
        TraCuuImei: { key: "TraCuuImei", title: "Tra cứu imei" },
    }
    const [state, setState] = React.useState({
        reportSelected: ReportList.CapQuyen.key,
        tuNgay: dayjs(formatDate(new Date())),
        denNgay: dayjs(formatDate(new Date())),
        nguoiCap: "",
        imei: "",
        laySoLieuClick: 0,
        isLoading: false,
    });
    const [reportData, setReportData] = useState({ rows: [], obj: "" })
    const ReportPayload = {
        tu_ngay: "",
        den_ngay: "",
        end_agent: "",
        login_username: "",
        imei: ""

    }
    const handleChange = (event) => {
        setState({ ...state, reportSelected: event.target.value });
        setReportData({rows:[],obj:""})
    };
    useEffect(() => {

    }, [state.laySoLieuClick])

    // const Child = React.memo(props => {
    //     console.log("rendered");
    //     let rows = [];
    //     for (let i = 0; i < 1000; i++)
    //         rows.push(<div key={i}>{i} {new Date().toTimeString()}</div>)
    //     return <React.Fragment>{props.name} {rows}</React.Fragment>;
    // },
    //     /*  (prevProps, nextProps) => {
    //         if (prevProps.name === nextProps.name) {
    //             return true; // props are equal
    //         }
    //         return false; // props are not equal -> update the component
    //     } */
    // );
    // Sử dụng React.memo() để bao bọc phần không cần render lại
    // const MemoizedPart = React.memo((props) => {
    //     console.log("MemoizedPart rerender")
    //     return <div>This part will not re-render. Name: {props.selectedReport} {reportData.data} </div>;
    // });

    const handleGetDataReport = async () => {
        let payload = {
            tu_ngay: formatDate(state.tuNgay),
            den_ngay: formatDate(state.denNgay),
            end_agent: state.nguoiCap,
            login_username: props.username,
            imei: state.imei,
        }
        let resultArr
        try {
            setState({ ...state, isLoading: true })
            
            switch (state.reportSelected) {
                case ReportList.CapQuyen.key:
                    resultArr = await reportBaoCaoCapquyen(payload)
                    setReportData({ ...reportData, rows: resultArr.data.data })
                    setState({ ...state, isLoading: false })
                    break;
                case ReportList.SapHetHan.key:
                    resultArr = await reportThueBaoSapHetHan(payload)
                    setReportData({ ...reportData, rows: resultArr.data.data })
                    setState({ ...state, isLoading: false })
                    break;
                case ReportList.DangHoatDong.key:
                    resultArr = await reportThueBaoDangHoatDong(payload)
                    setReportData({ ...reportData, rows: resultArr.data.data })
                    setState({ ...state, isLoading: false })
                    break;
                case ReportList.NgungHoatDong.key:
                    resultArr = await reportThueBaoNgungHoatDong(payload)
                    setReportData({ ...reportData, rows: resultArr.data.data })
                    setState({ ...state, isLoading: false })
                    break;
                case ReportList.MayHuy.key:
                    resultArr = await reportMayHuy(payload)
                    setReportData({ ...reportData, rows: resultArr.data.data })
                    setState({ ...state, isLoading: false })
                    break;
                case ReportList.ThanhToanCapDuoi.key:
                    resultArr = await reportThanhToanCapDuoi(payload)
                    setReportData({ ...reportData, rows: resultArr.data.data })
                    setState({ ...state, isLoading: false })
                    break;
                case ReportList.ThanhToanCapTren.key:
                    resultArr = await reportThanhToanAdmin(payload)
                    setReportData({...reportData, rows: resultArr.data.data })
                    setState({ ...state, isLoading: false })
                    break;
                case ReportList.BanHang.key:
                    resultArr = await reportBanHang(payload)
                    setReportData({ ...reportData, obj: resultArr.data.data })
                    setState({ ...state, isLoading: false })
                    break;
                case ReportList.TraCuuImei.key:
                    resultArr = await reportTraCuuImei(payload)
                    setReportData({ ...reportData, obj: resultArr.data.data })
                    setState({ ...state, isLoading: false })
                    break;
                default:
                    break;
            }
        }
        catch (e) {
            enqueueSnackbar(`Lỗi: ${e.message}`, { variant: "error" })
        }
    }
    const renderBaoCaoCapQuyen = () => {
        if (state.isLoading) {
            return <div> Đang lấy dữ liệu..... <CircularProgress size={"1rem"} /></div>
        }
        //
        let max = 0;
        let total = 0
        return (
            <table className="border-gray-400">
                <tr>
                    <th>imei</th>
                    <th>Ngày</th>
                    <th>Th</th>
                    <th>Ng cấp</th>
                    <th>Ghi chú</th>
                </tr>
                {
                    reportData.rows.map((item, index) => {
                        let cur = Number(item.so_thang);
                        total += cur;
                        if (cur > max) max = cur;
                        return (<tr key={index} className=" break-words break-all">
                            <td className="border border-gray-400">{item.assigned_imei}</td>
                            <td className="border border-gray-400">{item.active_date}</td>
                            <td className="border border-gray-400">{item.so_thang}</td>
                            <td className="border border-gray-400">{item.end_agent}</td>
                            <td className="border border-gray-400">{item.ghi_chu}</td>
                            {/* <div>{JSON.stringify(item)}</div> */}

                        </tr>)
                    })
                }
                <tr><th colSpan={5}>Tổng số {total} Max {max}</th></tr>
            </table>
        )
    }
    const renderThueBaoSapHetHan = () => {
        if (state.isLoading) {
            return <div> Đang lấy dữ liệu..... <CircularProgress size={"1rem"} /></div>
        }
        //

        let total = 0
        return (
            <table className="border-gray-400">
                <tr>
                    <th>imei</th>
                    <th>Version</th>
                    <th>Hạn sd</th>

                </tr>
                {
                    reportData.rows.map((item, index) => {
                        let cur = Number(item.so_thang);
                        total += 1;

                        return (<tr key={index} className=" break-words break-all">
                            <td className="border border-gray-400">{item.imei}</td>
                            <td className="border border-gray-400">{item.soft_version}</td>
                            <td className="border border-gray-400">{item.expire_date}</td>

                            {/* <div>{JSON.stringify(item)}</div> */}

                        </tr>)
                    })
                }
                <tr><th colSpan={3}>Tổng số {total}</th></tr>
            </table>
        )
    }
    const renderThueBaoDangHoatDong = () => {
        if (state.isLoading) {
            return <div> Đang lấy dữ liệu..... <CircularProgress size={"1rem"} /></div>
        }
        //

        let total = 0
        return (
            <table className="border-gray-400">
                <tr>
                    <th>imei</th>
                    <th>Version</th>
                    <th>Hạn sd</th>

                </tr>
                {
                    reportData.rows.map((item, index) => {
                        let cur = Number(item.so_thang);
                        total += 1;

                        return (<tr key={index} className=" break-words break-all">
                            <td className="border border-gray-400">{item.imei}</td>
                            <td className="border border-gray-400">{item.soft_version}</td>
                            <td className="border border-gray-400">{item.expire_date}</td>

                            {/* <div>{JSON.stringify(item)}</div> */}

                        </tr>)
                    })
                }
                <tr><th colSpan={3}>Tổng số {total}</th></tr>
            </table>
        )
    }
    const renderThueBaoNgungHoatDong = () => {
        if (state.isLoading) {
            return <div> Đang lấy dữ liệu..... <CircularProgress size={"1rem"} /></div>
        }
        //

        let total = 0
        return (
            <table className="border-gray-400">
                <tr>
                    <th>imei</th>
                    <th>Version</th>
                    <th>Hạn sd</th>

                </tr>
                {
                    reportData.rows.map((item, index) => {
                        let cur = Number(item.so_thang);
                        total += 1;

                        return (<tr key={index} className=" break-words break-all">
                            <td className="border border-gray-400">{item.imei}</td>
                            <td className="border border-gray-400">{item.soft_version}</td>
                            <td className="border border-gray-400">{item.expire_date}</td>

                            {/* <div>{JSON.stringify(item)}</div> */}

                        </tr>)
                    })
                }
                <tr><th colSpan={3}>Tổng số {total}</th></tr>
            </table>
        )
    }
    const renderHuyThang = () => {
        if (state.isLoading) {
            return <div> Đang lấy dữ liệu..... <CircularProgress size={"1rem"} /></div>
        }
        //

        let total = 0
        return (
            <table className="border-gray-400">
                <tr>
                    <th>imei</th>
                    <th>Ngày</th>
                    <th>T.trạng</th>
                    <th>Số th</th>

                </tr>
                {
                    reportData.rows.map((item, index) => {
                        let cur = Number(item.so_thang);
                        total += 1;

                        return (<tr key={index} className=" break-words break-all">
                            <td className="border border-gray-400">{item.assigned_imei}</td>
                            <td className="border border-gray-400">{item.deactive_date}</td>
                            <td className="border border-gray-400">{item.pending_deactive == "1" ? "Chờ" : "Xong"}</td>
                            <td className="border border-gray-400">{item.so_thang}</td>

                            {/* <div>{JSON.stringify(item)}</div> */}

                        </tr>)
                    })
                }
                <tr><th colSpan={4}>Tổng số {total}</th></tr>
            </table>
        )
    }
    const renderThanhToanCapDuoi = () => {
        if (state.isLoading) {
            return <div> Đang lấy dữ liệu..... <CircularProgress size={"1rem"} /></div>
        }
        //

        let total = 0
        return (
            <table className="border-gray-400">
                <tr>
                    <th>Đ.lý</th>
                    <th>Imei</th>
                    <th>th</th>
                    <th>Ngày</th>

                </tr>
                {
                    reportData.rows.map((item, index) => {
                        let cur = Number(item.so_thang);
                        total += cur;

                        return (<tr key={index} className=" break-words break-all">
                            <td className="border border-gray-400">{item.agent}</td>
                            <td className="border border-gray-400">{item.assigned_imei}</td>
                            <td className="border border-gray-400">{item.so_thang}</td>
                            <td className="border border-gray-400">{item.ttdate}</td>

                            {/* <div>{JSON.stringify(item)}</div> */}

                        </tr>)
                    })
                }
                <tr><th colSpan={4}>Tổng số {total}</th></tr>
            </table>
        )
    }
    const renderThanhToanAdmin = () => {
        if (state.isLoading) {
            return <div> Đang lấy dữ liệu..... <CircularProgress size={"1rem"} /></div>
        }
        //

        let total = 0
        return (
            <table className="border-gray-400">
                <tr>
                    <th>Đ.lý</th>
                    <th>Imei</th>
                    <th>th</th>
                    <th>Ngày</th>

                </tr>
                {
                    reportData.rows.map((item, index) => {
                        let cur = Number(item.so_thang);
                        total += cur;

                        return (<tr key={index} className=" break-words break-all">
                            <td className="border border-gray-400">{item.agent}</td>
                            <td className="border border-gray-400">{item.assigned_imei}</td>
                            <td className="border border-gray-400">{item.so_thang}</td>
                            <td className="border border-gray-400">{item.ttdate}</td>

                            {/* <div>{JSON.stringify(item)}</div> */}

                        </tr>)
                    })
                }
                <tr><th colSpan={4}>Tổng số {total}</th></tr>
            </table>
        )
    }
    const renderBanHang = () => {
        if (state.isLoading) {
            return <div> Đang lấy dữ liệu..... <CircularProgress size={"1rem"} /></div>
        }
        //

        let total = 0
        return (
            <div dangerouslySetInnerHTML={{ __html: reportData.obj?reportData.obj.HTML:"" }}></div>
        )
    }
    const renderTraCuuImei = () => {
        if (state.isLoading) {
            return <div> Đang lấy dữ liệu..... <CircularProgress size={"1rem"} /></div>
        }
        //

        let total = 0
        return (
            <div dangerouslySetInnerHTML={{ __html: reportData.obj?reportData.obj.HTML:"" }}></div>
        )
    }
    return (
        <div className="flex flex-col">
            <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 0.8 },
                }}
                noValidate
                autoComplete="off"
            >
                <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">Chọn báo cáo</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={state.reportSelected}
                        defaultValue={"sapHetHan"}
                        label="Chọn báo cáo"
                        onChange={handleChange}
                    >
                        <MenuItem value={ReportList.CapQuyen.key}>{ReportList.CapQuyen.title}</MenuItem>
                        <MenuItem value={ReportList.SapHetHan.key}>{ReportList.SapHetHan.title}</MenuItem>
                        {/* props.username == "admin" && */ <MenuItem value={ReportList.DangHoatDong.key}>{ReportList.DangHoatDong.title}</MenuItem>}
                        {/* props.username == "admin" &&  */<MenuItem MenuItem value={ReportList.NgungHoatDong.key}>{ReportList.NgungHoatDong.title}</MenuItem>}
                        {/* props.username == "admin" &&  */<MenuItem MenuItem value={ReportList.MayHuy.key}>{ReportList.MayHuy.title}</MenuItem>}
                        {/* props.username == "admin" && */ <MenuItem MenuItem value={ReportList.ThanhToanCapDuoi.key}>{ReportList.ThanhToanCapDuoi.title}</MenuItem>}
                        { props.username == "admin" &&  <MenuItem MenuItem value={ReportList.ThanhToanCapTren.key}>{ReportList.ThanhToanCapTren.title}</MenuItem>}
                        {props.username == "admin" && <MenuItem MenuItem value={ReportList.BanHang.key} > {ReportList.BanHang.title}</MenuItem>}
                        {/* props.username == "admin"  && */<MenuItem MenuItem value={ReportList.TraCuuImei.key} > {ReportList.TraCuuImei.title}</MenuItem>}

                    </Select >
                </FormControl >
                <FormControl size="small">
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="vi">
                        <MobileDatePicker
                            // open={state.showDateDlg}
                            value={state.tuNgay}
                            // onOpen={handleDateOpen}
                            // onClose={handleDateClose}
                            // inputFormat="dd/MM/yyyy"
                            onChange={(newValue) => {
                                setState((prestate) => ({ ...prestate, tuNgay: newValue }))
                            }
                            }
                            slotProps={{ textField: { size: 'small' } }}
                            label="Từ ngày"

                            onAccept={(value) => {
                                // acceptedDate.current = value.toISOString().substring(0, 10);
                                // props.onDateChanged(value.toISOString().substring(0, 10));
                            }}
                            renderInput={(params) => (
                                <TextField
                                    variant="standard"
                                    {...params}
                                    inputProps={{
                                        ...params.inputProps,
                                        style: {
                                            boxShadow: "none",
                                            width: 100,
                                            marginLeft: 10,
                                        },
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton
                                                    color={"primary"}
                                                    edge="start"
                                                // onClick={handleDateOpen}
                                                >
                                                    <CalendarMonthIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />

                    </LocalizationProvider>
                </FormControl>
                <FormControl size="small">
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="vi">
                        <MobileDatePicker
                            value={dayjs(state.denNgay)}
                            // open={state.showDateDlg}
                            // value={state.date}
                            // onOpen={handleDateOpen}
                            // onClose={handleDateClose}
                            // inputFormat="dd/MM/yyyy"
                            slotProps={{ textField: { size: 'small' } }}
                            label="Đến ngày"

                            onChange={(newValue) => {
                                setState((prestate) => ({ ...prestate, denNgay: newValue }))
                            }}
                            onAccept={(value) => {
                                // acceptedDate.current = value.toISOString().substring(0, 10);
                                // props.onDateChanged(value.toISOString().substring(0, 10));
                            }}
                            renderInput={(params) => (
                                <TextField

                                    variant="standard"
                                    {...params}
                                    inputProps={{
                                        ...params.inputProps,
                                        style: {
                                            boxShadow: "none",
                                            width: 100,
                                            marginLeft: 10,
                                        },
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton
                                                    color={"primary"}
                                                    edge="start"
                                                // onClick={handleDateOpen}
                                                >
                                                    <CalendarMonthIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />

                    </LocalizationProvider>
                </FormControl>
                <FormControl size="small">
                    <TextField label="Người cấp" size="small" value={state.nguoiCap}
                        onChange={(e) => {
                            setState((prevState) => ({ ...prevState, nguoiCap: e.target.value }))
                        }}
                    ></TextField>
                </FormControl>
                <FormControl size="small">
                    <TextField label="Imei" size="small"
                        value={state.imei}
                        onChange={(e) => {
                            setState((prevState) => ({ ...prevState, imei: e.target.value }))
                        }}
                    ></TextField>
                </FormControl>
            </Box >
            <Button size="small" variant="contained"
                onClick={handleGetDataReport}
            >Lấy số liệu</Button>
            {/*  <Child />
            <MemoizedPart reportData={reportData} /> */}
            {state.reportSelected == ReportList.CapQuyen.key && renderBaoCaoCapQuyen()}
            {state.reportSelected == ReportList.SapHetHan.key && renderThueBaoSapHetHan()}
            {state.reportSelected == ReportList.DangHoatDong.key && renderThueBaoDangHoatDong()}
            {state.reportSelected == ReportList.NgungHoatDong.key && renderThueBaoNgungHoatDong()}
            {state.reportSelected == ReportList.MayHuy.key && renderHuyThang()}
            {state.reportSelected == ReportList.ThanhToanCapDuoi.key && renderThanhToanCapDuoi()}
            {state.reportSelected == ReportList.ThanhToanCapTren.key && renderThanhToanAdmin()}
            {state.reportSelected == ReportList.BanHang.key && renderBanHang()}
            {state.reportSelected == ReportList.TraCuuImei.key && renderTraCuuImei()}
        </div >
    )
} 