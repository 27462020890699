import axios from 'axios';
// import { useSelector } from 'react-redux';
//DEV
// export const baseURL_V2='http://localhost:8083'
// export const baseURL='http://localhost:8080'
// export const baseURLServerMan='http://localhost:8084'


//DEPLOY CNLOT88
// export const baseURL="https://cnlot88.net/api"
// export const baseURL_V2="https://cnlot88.net"
// export const baseURLServerMan='https://cnlot88.net'

//DEPLOY Ilottery.win
// export const baseURL="http://10.211.55.5/ldvn"
export const baseURL="https://saigon2.win/ldvn"

const axiosClient = axios.create({
    baseURL: baseURL,
    timeout: 120000
})

export default axiosClient;

